import getRuntimeConfig from '~config';

const useDatoFeatureFlag = (marketId, devFeatureFlag, prodFeatureFlag) => {
  const config = getRuntimeConfig();
  const isDev = !config.build.stage || config.build.stage === 'dev';

  const isFeatureFlagEnabled = isDev ? devFeatureFlag : prodFeatureFlag;

  if (!isFeatureFlagEnabled) return false;

  return isFeatureFlagEnabled?.toLowerCase().includes(marketId?.toLowerCase());
};

export default useDatoFeatureFlag;
