import React, { useState } from 'react';

import getRuntimeConfig from '~config';

import { useAuth, useDato } from '~app/hooks';

import GQL_DATO_LAYOUT_HEADER from '~app/apollo/dato/queries/layout_header';

import * as R from 'ramda';
import {
  InternalHeader,
  InternalHeaderTitleAnchor
} from '@polestar/component-warehouse-react/internal';
import { Icons } from '@polestar/component-warehouse-react';
import { generateUrl } from '~routes/helpers';
import { useNavigate } from 'react-router-dom';

const config = getRuntimeConfig();

const Header = () => {
  const { logout, userFullName } = useAuth();
  const navigate = useNavigate();

  const [userInitials] = useState(
    R.compose(R.join(''), R.map(R.head), R.split(' '))(userFullName() ?? '')
  );

  const [dato] = useDato(GQL_DATO_LAYOUT_HEADER);

  return (
    <InternalHeader
      title={
        <InternalHeaderTitleAnchor
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            navigate('/');
          }}
        >
          {dato?.header?.title}
        </InternalHeaderTitleAnchor>
      }
      menuButton
      menuButtonProps={{ enableNavigationDrawerFuture: true }}
      search={false}
      avatarButtonProps={{
        initials: userInitials,
        ['aria-label']: 'profile'
      }}
      avatarContextMenuProps={{
        items: [
          {
            label: dato?.header?.logout,
            onClick: () => {
              logout();
            }
          }
        ]
      }}
      actions={[
        {
          icon: Icons.support,
          as: 'a',
          href: generateUrl('support'),
          onClick: (e) => {
            e.preventDefault();
            navigate(generateUrl('support'));
          },
          ['data-testid']: 'support-link'
        }
      ]}
      dashboardButtonProps={{
        ['data-testid']: 'portal-menu',
        ['aria-label']: 'dashboard'
      }}
      dashboardContextMenuProps={{
        items: R.map(R.pipe(R.assoc('data-testid', 'portal-menu-item'), R.assoc('as', 'a')), [
          {
            href: config.portalUrls.spaces,
            label: dato?.header?.menuSpacePortal
          },
          {
            href: config.portalUrls.visitorMoment,
            label: dato?.header?.menuVisitorMoment
          },
          {
            active: true,
            href: '#',
            label: dato?.header?.menuTestDrivePortal
          },
          {
            label: dato?.header?.menuHandoverPortal,
            href: config.portalUrls.handover
          }
        ])
      }}
    />
  );
};
export default Header;
