import { useContext } from 'react';
import { FeatureFlagContext } from '~app/providers';

const useAwsFeatureFlags = () => {
  const { loading, featureFlags } = useContext(FeatureFlagContext);

  return { loading, featureFlags };
};

export default useAwsFeatureFlags;
