/**
 * We need to enable typenames because we use GraphQL interfaces.
 * For queries there is no problem to ask for typename but for
 * mutations it will be a problem if we don't add field typename
 * for all inputs and we dont want to pollute our inputs.
 *
 * There is an ongoing discussion (and has been for a long time)
 * in Apollo GraphQL community on how to handle this best and right
 * now the best solution is to strip out typename as a middleware.
 *
 * Will check back on this issue now and then to see if there comes
 * an official solution. The solution below comes from a blog post and
 * and from discussions on Github issues.
 *
 * Blog post: https://librenepal.com/article/remove-typenames-in-apollo-graphql/
 *
 */

const omitDeepArrayWalk = (arr, key) => {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === 'object') return omitDeep(val, key);
    return val;
  });
};

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

const omitTypeName = (operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }

  return forward(operation);
};

export default omitTypeName;
