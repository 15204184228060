import React, { createContext, useEffect, useState } from 'react';

import getRuntimeConfig from '~config';

import Spinner from '~app/components/Spinner';

import * as R from 'ramda';
import { markets } from './marketList';

const config = getRuntimeConfig();

export const FALLBACK_LOCALE = 'en';
export const ENGLISH_LOCALES = ['en_GB', 'en_US', 'en_CA', 'en_AU', 'en_IE'];

/* End of large markets content */

// Locale values that can be used in the queries to Dato.
// The browser locale value is usually "en" or "en-GB" while
// Dato uses an underscore ("_") instead of a dash ("-").
const languageCodes = R.uniq(R.map(R.prop('languageCode'), markets));

const cmsLocales = R.map(
  (languageCode) =>
    R.map(R.prop('cmsLocale'), R.filter(R.propEq(languageCode, 'languageCode'), markets)),
  languageCodes
);

/*This object will map each language code to an array of cmsLocales that use that langauge code, e.g.
 * {
 *   ...
 *   en: [
 *     ...
 *     "en_GB",
 *     "en_CA",
 *     "en_US",
 *     ...
 *   ],
 *   es: [
 *     "es_ES"
 *   ],
 *   fi: [
 *     "fi_FI"
 *   ],
 *   fr: [
 *     "fr_BE",
 *     "fr_LU",
 *     ...
 *   ],
 *   ...
 * }
 * */
const DATO_LOCALES = R.zipObj(languageCodes, cmsLocales);

export const LocaleContext = createContext([]);

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState();

  useEffect(() => {
    const browserLocale = R.compose(R.head, R.split('-'))(window?.navigator?.language ?? '');

    // Config values can be undefined but should be a comma separated string of locales.
    let supportedLocales = config?.dato?.supportedLocales ?? '';
    supportedLocales = R.compose(
      R.filter(R.compose(R.not, R.isEmpty)),
      R.map(R.trim),
      R.split(','),
      R.defaultTo('')
    )(supportedLocales);

    if (R.includes(browserLocale, supportedLocales)) {
      // use the first cmsLocale that uses the browserlocale
      setLocale(R.pathOr(FALLBACK_LOCALE, [browserLocale, 0], DATO_LOCALES));
    } else {
      setLocale(FALLBACK_LOCALE);
    }
  }, []);

  if (R.isNil(locale)) {
    return <Spinner />;
  }

  return <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;

/*
 * Returns an array of CMS locales corresponding to a given marketId,
 * or an empty array if not found.
 *
 * @param {string} marketId (countryCode)
 * @returns {array} - An array of cmsLocales
 * e.g. ['fr_CH', 'sv_SE', 'nl-be']
 * */
export const getCmsLocalesByMarketId = (marketId) => {
  return R.map(R.prop('cmsLocale'), R.filter(R.propEq(marketId, 'countryCode'), markets ?? []));
};

/*
 * Return true if the list of locales contains any English locales,
 *
 * @param {array} list of locales
 * @returns {boolean}
 * e.g. ['fr_CH', 'sv_SE', 'nl-be']
 * */
export const containsAnyEnglishLocale = (locales) => {
  return R.intersection(locales, ENGLISH_LOCALES).length > 0;
};

/*
 * Returns an first locale of CMS locales corresponding to a given marketId,
 * or an empty locale if not found.
 *
 * @param {string} marketId (countryCode)
 * @returns locale - if there are multiple locales for the market return first locale
 * fecthing first locale since settings will be same for the market.
 * e.g. 'fr_CH'
 * */
export const getCmsLocaleByMarketId = (marketId) => {
  const filteredMarkets = R.map(
    R.prop('cmsLocale'),
    R.filter(R.propEq(marketId, 'countryCode'), markets ?? [])
  );
  return filteredMarkets[0] ?? null;
};
