import { useEffect, useRef } from 'react';

import useNotify, { ERROR, SUCCESS } from '~app/hooks/use_notify';

import isDeepEqual from 'fast-deep-equal/react';

import { allError, allOk } from '~app/lib/graphql/response';

import * as R from 'ramda';

import { toPath } from '~app/lib/string';

const dataPath = R.compose(R.append('data'), R.dropLast(1), R.split('.'), R.defaultTo(''));

const STANDARD_ERROR_MESSAGE = 'There was an error';

const isArr = R.is(Array);

const isArrayOfArray = R.compose(R.and(isArr, R.compose(isArr, R.head)), R.defaultTo([]));

/**
 * extractStatusCode
 *
 * Extracts one or more status codes based on the
 * statusPath that can consist of one or more paths.
 *
 * Returns undefined if no status is found at the
 * given path.
 *
 */
const extractStatusCode = (data = {}, statusPath) => {
  const paths = isArrayOfArray(statusPath) ? statusPath : [statusPath];
  return R.map(R.path(R.__, data), paths);
};

/**
 * extractFirstErrorMessages
 *
 * Goes through all responses and try to extract the error
 * messages in the response data object.
 *
 * Returns the first error message found if there are several errors.
 *
 */
const extractFirstErrorMessages = (data = {}, statusPath) => {
  const paths = isArrayOfArray(statusPath) ? statusPath : [statusPath];

  const errorPaths = R.map(
    R.compose(R.flatten, R.append(['error', 'message']), R.dropLast(1)),
    paths
  );

  const errors = R.map(R.pathOr(STANDARD_ERROR_MESSAGE, R.__, data), errorPaths);

  return R.head(errors);
};

/**
 * hasMessage
 *
 * Returns a bool to wether the sent in object has any keys
 * that corresponds to our known message props, such as message,
 * prefixMessage etc.
 *
 */
const hasMessage = R.compose(
  R.gt(R.__, 0),
  R.length,
  R.intersection(['prefixMessage', 'message']),
  R.keys
);

/**
 * useResponse
 *
 * Function to make it easier to work with GraphQL responses.
 * Provide a callback and/or a notification message to the user
 * on error and/or success.
 *
 * See tests for examples.
 *
 */
const useResponse = ({
  // GraphQL error object from a response
  error,
  // GraphQL data object from a response
  data,
  // Path to where the response status path resides
  // can be mutliple paths then divided by ";"
  // eg "cars.status;hubs.status"
  statusPath = 'response.status',
  // Handle case of response being an error
  onError,
  // Handlse case of a response being successfull
  onSuccess
}) => {
  if (R.isNil(onError) && R.isNil(onSuccess)) {
    return;
  }

  const dataRef = useRef(data);
  const errorRef = useRef(error);

  if (!isDeepEqual(dataRef.current, data)) {
    dataRef.current = data;
  }

  if (!isDeepEqual(errorRef.current, error)) {
    errorRef.current = error;
  }

  const sendErr = useNotify(ERROR);
  const sendOk = useNotify(SUCCESS);

  // Safe to be used in useEffect for success and error
  // as long as they keep their dependecy towards "dataRef" and "statusPath"
  const statusCodes = extractStatusCode(dataRef.current, toPath(statusPath));

  useEffect(() => {
    if (!onSuccess || !R.isNil(error)) {
      return;
    }

    const notify = hasMessage(onSuccess);

    if (!allOk(statusCodes)) {
      return;
    }

    if (notify) {
      sendOk(onSuccess?.message);
    }

    // responseData is the data under the key "data" of a response.
    // All our responses have the convention to have "data" if they return something.
    // If the statusPath is a single path, the data is returned as
    // an object. If there is multiple paths they are returned as objects
    // inside of an array.
    if (onSuccess?.callback) {
      const paths = R.split(';', statusPath);

      const responseData = R.map(x => R.path(dataPath(x), dataRef.current))(paths);

      onSuccess.callback(R.length(responseData) === 1 ? R.head(responseData) : responseData);
    }
  }, [statusPath, dataRef.current, errorRef.current]);

  useEffect(() => {
    if (!onError) {
      return;
    }

    const notify = hasMessage(onError);

    if (!allError(statusCodes) && R.isNil(error)) {
      return;
    }

    if (notify) {
      let errMsg = error?.message;

      if (R.either(R.isNil, R.isEmpty)(errMsg)) {
        errMsg = extractFirstErrorMessages(data, toPath(statusPath));
      }

      if (onError?.message) {
        sendErr(onError.message);
      } else if (onError?.prefixMessage) {
        sendErr(`${onError.prefixMessage}: ${errMsg}`);
      } else {
        sendErr(errMsg);
      }
    }

    if (onError?.callback) {
      onError.callback();
    }
  }, [statusPath, dataRef.current, errorRef.current]);
};

export default useResponse;
