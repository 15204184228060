import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InternalPolestarApp } from '@polestar/component-warehouse-react/internal';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloProvider,
  DatoBaseProvider,
  DeviceProvider,
  FeatureFlagProvider,
  I18nProvider,
  LocaleProvider,
  MarketsProvider,
  RolesProvider,
  TimezonesProvider,
  pca
} from '~app/providers';
import App from './App';

export const AppWrapper = () => {
  return (
    <>
      <InternalPolestarApp>
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <LocaleProvider>
              <ApolloProvider>
                <DatoBaseProvider>
                  <DeviceProvider>
                    <RolesProvider>
                      <MarketsProvider>
                        <TimezonesProvider>
                          <I18nProvider>
                            <FeatureFlagProvider>
                              <BrowserRouter>
                                <CookiesProvider defaultSetOptions={{ path: '/' }}>
                                  <App />
                                </CookiesProvider>
                              </BrowserRouter>
                            </FeatureFlagProvider>
                          </I18nProvider>
                        </TimezonesProvider>
                      </MarketsProvider>
                    </RolesProvider>
                  </DeviceProvider>
                </DatoBaseProvider>
              </ApolloProvider>
            </LocaleProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </InternalPolestarApp>
    </>
  );
};
