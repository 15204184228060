import React, { createContext } from 'react';

import { useDato } from '~app/hooks';

import Spinner from '~app/components/Spinner';
import ErrorPage from '~app/components/ErrorPage';

import GQL_DATO_BASE from '~app/apollo/dato/queries/dato_base';

export const DatoBaseContext = createContext([]);

/**
 * DatoBaseProvider
 *
 * Used to fetch generic and widely used dato translations early in the app's lifecycle.
 *
 */
const DatoBaseProvider = ({ children }) => {
  const [dato, loading, error] = useDato(GQL_DATO_BASE);

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Spinner />;
  }

  return <DatoBaseContext.Provider value={dato}>{children}</DatoBaseContext.Provider>;
};

export default DatoBaseProvider;
