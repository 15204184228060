import { useEffect, useRef, useState, useCallback } from 'react';

import isDeepEqual from 'fast-deep-equal/react';

import * as R from 'ramda';

const nilOrEmpty = R.either(R.isNil, R.isEmpty);

/*
 * syncChanges
 *
 * Makes sure our local changeset still applies after
 * retriving new data from the server.
 *
 * If a bookingId in the changeset is no longer present
 * in the server data, it gets removed. If it does exist
 * it stays with the local changes applied to it.
 *
 */
export const syncChanges = (bookings, changedBookings) => {
  if (nilOrEmpty(changedBookings)) {
    return changedBookings;
  }

  const changedIds = R.compose(R.map(R.prop('bookingId')), R.flatten, R.values)(changedBookings);

  const bookingsById = R.compose(
    R.reduce((xs, x) => ({ ...xs, [x?.bookingId]: x }), {}),
    R.flatten,
    R.map(R.values),
    R.values
  )(bookings);

  const bookingIds = R.keys(bookingsById);

  const validIds = R.uniq(bookingIds, changedIds);

  return R.reduce(
    (dates, date) => {
      const changesForDate = R.filter((y) => {
        const isValid = R.includes(y.bookingId, validIds);
        const isSame = isDeepEqual(bookingsById?.[y?.bookingId], y);

        return isValid && !isSame;
      }, changedBookings[date]);

      if (R.isEmpty(changesForDate)) {
        return dates;
      }

      return {
        ...dates,
        [date]: changesForDate
      };
    },
    {},
    R.keys(changedBookings)
  );
};

/*
 * bookingsByDate
 *
 * Returns data for a specific date with our changes applied.
 *
 */
export const bookingsByDate = (bookings, changedBookings, date) => {
  if (nilOrEmpty(bookings) || nilOrEmpty(date)) {
    return;
  }

  const changedForDate = changedBookings?.[date] ?? [];

  if (nilOrEmpty(changedForDate)) {
    return bookings?.[date] ?? [];
  }

  return R.map((x) => {
    const changedBooking = changedForDate?.[x?.bookingId];

    return changedBooking ?? x;
  }, bookings?.[date] ?? []);
};

/**
 * useMutableSchedule
 *
 * All props are mandatory and needed for the mutable schedule hook to work.
 *
 * The hook is responsible for keeping track of changes and returns a state
 * to be used for the BookingSchedule.
 *
 * useMutableSchedule does not currentely support changes to arguments
 * openingHours and dates. Changes to these will be ignored.
 *
 *
 * @param {
 *   dates: {array} - Array of all dates in the format YYYY-MM-DD.
 *   openingHours: {array} - Array of openingHours objects td_OpeningHours.
 *   bookings: {object} - All bookings for all dates. Expects to have structure {'2021-12-24': [{...}, {...}], '2021-12-25': [{...}]}.
 * }
 *
 *
 * @returns {
 *   setDate: {function}, - Sets the selected date. Must be one of the dates in the dates argument.
 *   mutateBookingById: {function}, - Mutates a booking that is available on selected date by bookingId.
 *   mutateBookingsByQuery: {function}, - Mutates bookings that is available on the selected date by a callback query function.
 *   state: {
 *     selectedDate: {string}, - Selected date.
 *     selectedOpeningHours: {object}, - Opening hours for selected date.
 *     selectedBookings: {array}, - Bookings with potenially local changes applied for selected date.
 *     changedBookings: {array}, - Local changes.
 *   }
 * }
 */
const useMutableSchedule = ({ dates, openingHours, bookings }) => {
  const [selectedDate, setSelectedDate] = useState(R.head(dates ?? []));
  const [selectedOpeningHours, setSelectedOpeningHours] = useState(R.head(openingHours ?? []));
  const [selectedBookings, setSelectedBookings] = useState(bookings?.[R.head(dates ?? [])] ?? []);

  const [changedBookings, setChangedBookings] = useState({});

  const bookingsRef = useRef(bookings);
  const changedBookingsRef = useRef(changedBookings);
  const openingHoursRef = useRef(openingHours);

  if (!isDeepEqual(bookingsRef.current, bookings)) {
    bookingsRef.current = bookings;
  }

  if (!isDeepEqual(changedBookingsRef.current, changedBookings)) {
    changedBookingsRef.current = changedBookings;
  }

  if (!isDeepEqual(openingHoursRef.current, openingHours)) {
    openingHoursRef.current = openingHours;
  }

  useEffect(() => {
    const nextChangedBookings = syncChanges(bookings, changedBookings);

    if (isDeepEqual(nextChangedBookings, changedBookings)) {
      setSelectedBookings(bookingsByDate(bookings, changedBookings, selectedDate));
    } else {
      setChangedBookings(nextChangedBookings);
    }
  }, [bookingsRef.current]);

  useEffect(() => {
    setSelectedBookings(bookingsByDate(bookings, changedBookings, selectedDate));
  }, [changedBookingsRef.current]);

  useEffect(() => {
    setSelectedOpeningHours(openingHours?.[R.findIndex(R.equals(selectedDate), dates)]);
  }, [openingHoursRef.current]);

  useEffect(() => {
    setSelectedBookings(bookingsByDate(bookings, changedBookings, selectedDate));
    setSelectedOpeningHours(openingHours?.[R.findIndex(R.equals(selectedDate), dates)]);
  }, [selectedDate]);

  const handleDateChange = (newSelectedDate) => {
    if (R.isNil(newSelectedDate) || !R.includes(newSelectedDate, dates)) {
      return;
    }

    setSelectedDate(newSelectedDate);
  };

  const handleMutateBookingById = useCallback(
    (bookingId, booking) => {
      const foundBooking = R.find(R.propEq(bookingId, 'bookingId'), selectedBookings);

      if (R.isNil(foundBooking)) {
        return;
      }

      setChangedBookings(
        syncChanges(
          bookings,
          R.set(
            R.lensPath([selectedDate, bookingId]),
            R.mergeDeepRight(foundBooking, booking),
            changedBookingsRef.current
          )
        )
      );
    },
    [selectedDate, selectedBookings]
  );

  const handleMutateBookingsByQuery = useCallback(
    (queryFn) => {
      if (!R.is(Function, queryFn)) {
        return;
      }

      const bookingsToMutate = queryFn(selectedBookings);

      const mutatedBookings = R.map(
        (x) => R.mergeDeepRight(R.find(R.propEq(x?.bookingId, 'bookingId'), selectedBookings), x),
        bookingsToMutate
      );

      setChangedBookings(
        syncChanges(
          bookings,
          R.set(
            R.lensProp(selectedDate),
            R.reduce(
              (xs, x) => ({
                ...xs,
                [x?.bookingId]: x
              }),
              changedBookings?.[selectedDate] ?? {},
              mutatedBookings
            ),
            changedBookingsRef.current
          )
        )
      );
    },
    [selectedDate, selectedBookings]
  );

  return {
    setDate: handleDateChange,
    mutateBookingById: handleMutateBookingById,
    mutateBookingsByQuery: handleMutateBookingsByQuery,
    state: {
      selectedDate,
      selectedOpeningHours,
      selectedBookings,
      changedBookings
    }
  };
};

export default useMutableSchedule;
