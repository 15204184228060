import React, { createContext, useState, useEffect } from 'react';

import { device } from '~app/lib/media-query';

export const DeviceContext = createContext();

/**
 *  Provides a context for its children that keep track of current screen size.
 *  Note that the listeners are only registered on mount and never unregistered,
 *  they will and should always exist in the applications context.
 */
const DeviceProvider = ({ children }) => {
  const [state, setState] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false
  });

  useEffect(() => {
    const mobileMatch = window.matchMedia(device.mobile);
    const tabletMatch = window.matchMedia(device.tablet);
    const desktopMatch = window.matchMedia(device.desktop);

    const update = (device, isMatch) => setState(pre => ({ ...pre, [device]: isMatch }));
    const onMobile = mobile => update('isMobile', mobile.matches);
    const onTablet = tablet => update('isTablet', tablet.matches);
    const onDesktop = desktop => update('isDesktop', desktop.matches);

    mobileMatch.addListener(onMobile);
    tabletMatch.addListener(onTablet);
    desktopMatch.addListener(onDesktop);

    setState({
      isMobile: mobileMatch.matches,
      isTablet: tabletMatch.matches,
      isDesktop: desktopMatch.matches
    });
  }, []);

  return <DeviceContext.Provider value={state}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;
