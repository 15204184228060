import React, { createContext, useState } from 'react';

import Spinner from '~app/components/Spinner';
import ErrorPage from '~app/components/ErrorPage';

import { useResponse } from '~app/hooks';

import { useQuery } from '@apollo/client';
import GQL_ROLES from '~app/apollo/queries/roles';
import * as R from 'ramda';

export const RolesContext = createContext([]);

const RolesProvider = ({ children }) => {
  const [roles, setRoles] = useState([]);
  const [hasError, setHasError] = useState(false);

  const { loading, error, data } = useQuery(GQL_ROLES);

  useResponse({
    error,
    data,
    onError: {
      prefixMessage: 'Could not fetch roles',
      callback: () => setHasError(true)
    },
    onSuccess: {
      callback: data => setRoles(data ?? [])
    }
  });

  if (hasError || (!loading && R.isEmpty(data?.response?.data))) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Spinner />;
  }

  return <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>;
};

export default RolesProvider;
