// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all process.env.XXX are undefined.
const getRuntimeConfig = () => ({
  application: {
    // Define parameters specific to the application itself (environment-based or not)
    baseUrl: process.env.APP_BASE_URL,
    // Enable ServiceWorker cache
    enableSW: false, // ['production'].includes(process.env.NODE_ENV),
    // Determines if the application is ran in China.
    isChina: process.env.APP_IS_CHINA === 'true' || false
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    baseUrl: process.env.API_BASE_URL
  },
  auth: {
    authority: process.env.AZURE_AUTHORITY_URI,
    clientId: process.env.AZURE_CLIENT_ID,
    redirectUri: process.env.AZURE_REDIRECT_URI,
    disableForceRefreshToken: process.env.AUTH_DISABLE_FORCE_REFRESH_TOKEN === 'true' || false
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: process.env.DATO_ASSET_BASE_URL,
    baseUrl: process.env.DATO_BASE_URL,
    previewUrl: process.env.DATO_PREVIEW_BASE_URL,
    token: process.env.DATO_TOKEN,
    supportedLocales: process.env.DATO_SUPPORTED_LOCALES
  },
  build: {
    // Define parameters specific to the build environment (most likely environment-based)
    mode: process.env.NODE_ENV, // "development" | "production"
    stage: process.env.STAGE, // "dev" | "staging" | "qa" | "production"
    isomorphic: !process.env.CSR,
    // crossOriginLoading attribute for webpack (we may want to switch between 'anonymous' and 'use-credentials' based on the stage)
    // https://webpack.js.org/configuration/output/#outputcrossoriginloading
    crossOrigin: process.env.CROSS_ORIGIN || 'anonymous'
  },
  clientFacing: {
    baseUrl: process.env.CLIENT_FACING_BASE_URL,
    eventUrl: process.env.CLIENT_FACING_EVENT_URL
  },
  google: {
    maps: process.env.GOOGLE_MAPS_API_KEY,
    locationUrl: process.env.GOOGLE_MAPS_LOCATION_URL || ''
  },
  kakao: {
    locationUrl: process.env.KAKAO_MAPS_LOCATION_URL || ''
  },
  amap: {
    maps: process.env.AMAP_API_KEY,
    locationUrl: process.env.AMAP_LOCATION_URL || ''
  },
  digitalSigningMarkets: process.env.DIGITAL_SIGNING_MARKETS,
  portalUrls: {
    spaces: process.env.PORTAL_URLS_SPACES,
    visitorMoment: process.env.PORTAL_URLS_VISITOR_MOMENT,
    handover: process.env.PORTAL_URLS_HANDOVER
  },
  support: {
    url: process.env.SERVICE_NOW_URL
  },
  experienceCloud: {
    url: process.env.EXPERIENCE_CLOUD
  },
  datadog: {
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    applicationId: process.env.DATADOG_APPLICATION_ID
  },
  stage: process.env.STAGE
});

export default getRuntimeConfig;
