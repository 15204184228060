import React from 'react';
import { Notyf } from 'notyf';

export default React.createContext(
  new Notyf({
    ripple: false,
    position: {
      x: 'center',
      y: 'top'
    },
    types: [
      {
        type: 'error',
        icon: false,
        duration: 15000,
        dismissible: true
      }
    ]
  })
);
