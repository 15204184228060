import * as R from 'ramda';

const ERROR_CODES = [400, 401, 403, 404, 405, 408, 409, 413, 415, 422, 424, 500, 501, 503];
const SUCCESS_CODES = [200, 201, 204];

export const isError = (data, statusPath = ['response', 'status']) =>
  R.compose(R.flip(R.includes)(ERROR_CODES), R.path(statusPath))(data);

export const isOk = (data, statusPath = ['response', 'status']) =>
  R.compose(R.flip(R.includes)(SUCCESS_CODES), R.path(statusPath))(data);

export const allOk = R.compose(
  R.ifElse(R.isEmpty, R.always(false), R.all(R.equals(true))),
  R.map(R.flip(R.includes)(SUCCESS_CODES)),
  R.defaultTo([])
);

export const allError = R.compose(
  R.ifElse(R.isEmpty, R.always(false), R.all(R.equals(true))),
  R.map(R.flip(R.includes)(ERROR_CODES)),
  R.defaultTo([])
);
