import { useContext } from 'react';

import { DatoBaseContext } from '~app/providers';

const useDatoPrefetch = () => {
  const datoBase = useContext(DatoBaseContext);
  return datoBase ?? {};
};

export default useDatoPrefetch;
