import routes from './definition';

export const generateUrl = (name, parameters = {}) => {
  const route = routes.find((route) => route.name === name);

  if (!route) {
    throw new Error(`Cannot build URL for unknown route ${name}.`);
  }

  // Replace route path variables with provided parameters ("/:id" --> "/1")
  let finalUrl = Object.keys(parameters).reduce(
    (url, parameterName) =>
      url.replace(new RegExp(`:${parameterName}`, 'gi'), parameters[parameterName]),
    route.path
  );
  return finalUrl.replace(/\/\*/g, '');
};
