import { Orientations, Paragraph, Spacer, Spacings } from '@polestar/component-warehouse-react';
import React from 'react';
import { Container, SubTitle, Title } from './style';

export const PageTitle = ({ title, subTitle, description }) => {
  return (
    <Container>
      <Title className="page-title" data-testid="page-title">
        {title}
      </Title>

      {subTitle && (
        <SubTitle className="sub-title" data-testid="page-sub-title">
          {subTitle}
        </SubTitle>
      )}

      {description && (
        <>
          <Spacer orientation={Orientations.vertical} spacing={Spacings.xSmall} />
          <Paragraph>{description}</Paragraph>
        </>
      )}
    </Container>
  );
};

export default PageTitle;
