import { useMemo } from 'react';
import GET_SHARED_COUNTRIES from '~app/apollo/queries/get_shared_countries.gql';
import { useDato } from '~app/hooks';

const useSharedCountries = (marketId) => {
  const [data, loading] = useDato(GET_SHARED_COUNTRIES, true, 'cache-first');

  const countryCodes = useMemo(() => {
    if (data && !loading) {
      const { countryList1, countryList2, countryList3 } = data;

      const combinedCountries = [...countryList1, ...countryList2, ...countryList3];

      const { selectedCountry, sortedCountries } = combinedCountries.reduce(
        (accumulator, countryValue) => {
          const { country, callingCode, code } = countryValue;
          const formattedCountry = {
            label: country,
            value: callingCode,
            countryCode: callingCode?.toString(),
            code: code?.toLowerCase()
          };

          if (formattedCountry.code === marketId) {
            accumulator.selectedCountry = formattedCountry;
          } else {
            accumulator.sortedCountries.push(formattedCountry);
          }

          return accumulator;
        },
        { selectedCountry: null, sortedCountries: [] }
      );

      sortedCountries.sort((a, b) => a.label.localeCompare(b.label));

      if (selectedCountry) {
        return [selectedCountry, ...sortedCountries];
      }

      return sortedCountries;
    }
  }, [data, loading, marketId]);

  return { countryCodes };
};

export default useSharedCountries;
