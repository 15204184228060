import * as R from 'ramda';

/**
 * adjustNewLine
 * Adjusts the new line character from a textarea to a
 * standard new line character.
 *
 * @param {string}
 * @returns {strings}
 *
 */
export const adjustNewline = R.compose(R.replace(/↵/g, '\n'), R.defaultTo(''));

/**
 * capitalize
 * Capitalize a string
 *
 * @param {string}
 * @returns {string}
 *
 */
export const capitalize = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.compose(R.toLower, R.tail)]),
  R.defaultTo('')
);

/**
 * strip
 * Takes a number and returns a function that
 * after the sent in number strips the string and
 * adds "..." to the end.
 *
 * @param {int} charCount
 * @returns {func}
 * @param {string}
 * @returns {string}
 *
 */
export const strip = count =>
  R.compose(
    R.ifElse(
      R.compose(R.flip(R.gt)(count), R.length),
      R.compose(R.flip(R.concat)('...'), R.trim, R.join(''), R.take(count)),
      R.join('')
    ),
    R.split(''),
    R.defaultTo('')
  );

/**
 * combine
 * Takes either a string or an array and splits them
 * with a space and a delimeter. If no delitemer char is
 * sent, a default is used.
 *
 * @param {string|array} parts
 * @param {string} delimeter
 * @returns {string}
 *
 */
export const combine = (parts, delimeter = '/') =>
  R.compose(
    R.join(' ' + delimeter + ' '),
    R.reject(R.either(R.isNil, R.isEmpty)),
    R.defaultTo([])
  )(parts);

/**
 * splitAndCapitalize
 *
 * Splits a string on delimiter (defaulted to "_")
 * and join them by a space with the first word being capitalized.
 *
 * @param {string}
 * @returns {string}
 *
 */
export const splitAndCapitalize = (text, delimiter = '_') => {
  return R.compose(
    R.join(' '),
    R.flatten,
    R.juxt([R.compose(capitalize, R.head), R.compose(R.map(R.toLower), R.tail)]),
    R.split(delimiter),
    R.defaultTo('')
  )(text);
};

/**
 * toPath
 *
 * For single paths accepts a string like "response.status"
 * and for multiple paths seperates each path with ";"
 * for example "cars.status;hubs.status".
 *
 * Returns an array of one or more paths splited at "."
 * for example "response.status" => ['response', 'status']
 * or "cars.status, hubs.status" => [['cars', 'status'], ['hubs', 'status']]
 *
 */
export const toPath = str => {
  const arrays = R.compose(R.map(R.split('.')), R.split(';'))(str);
  return R.filter(x => !R.isEmpty(R.head(x)))(arrays);
};

/**
 *
 * strTempl
 *
 * Takes a string that can contain "{}" and replace them with
 * the value from the values array argument. First {} is matched to
 * the first item in array and so on.
 *
 * Eg strTemplate("I love {} and {}", ["f00d", "b33r"]) => "I love f00d and b33r"
 *
 */
export const strTempl = (str = '', values = []) =>
  R.reduce((xs, x) => R.replace('{}', x, xs), str)(R.map(R.defaultTo('<?>'), values));
