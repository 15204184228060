import React, { createContext, useState } from 'react';

import { useResponse, useAuth } from '~app/hooks';
import { ROLES } from '~app/hooks/useAuth';

import Spinner from '~app/components/Spinner';
import ErrorPage from '~app/components/ErrorPage';

import { useQuery } from '@apollo/client';
import GQL_MARKETS from '~app/apollo/queries/market_names';

import * as R from 'ramda';

export const MarketsContext = createContext([]);

const MarketsProvider = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const { authResources, hasRole } = useAuth();

  const isPsHQ = hasRole(ROLES.Hq);

  const [markets, setMarkets] = useState([]);

  const { loading, error, data } = useQuery(GQL_MARKETS);

  useResponse({
    error,
    data,
    onError: {
      prefixMessage: 'Could not fetch markets',
      callback: () => setHasError(true)
    },
    onSuccess: {
      callback: data => {
        const flipIncludes = R.flip(R.includes)(R.propOr([], 'marketId', authResources()));

        // If role is not HQ, filter out markets to what the user has access to.
        setMarkets(
          R.compose(
            R.sortBy(R.prop('marketName')),
            R.map(m => ({ ...m, locale: R.path(['language', 0, 'locale'])(m) })),
            R.ifElse(
              R.always(isPsHQ),
              R.identity,
              R.filter(R.compose(flipIncludes, R.prop('marketId')))
            )
          )(data ?? [])
        );
      }
    }
  });

  if (hasError || (!loading && R.isEmpty(markets))) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Spinner />;
  }

  return <MarketsContext.Provider value={markets}>{children}</MarketsContext.Provider>;
};

export default MarketsProvider;
