import { Center, Spinner as CWSpinner, SpinnerSizes } from '@polestar/component-warehouse-react';
import React from 'react';

const Spinner = () => (
  <Center>
    <CWSpinner data-testid="loading" size={SpinnerSizes.medium} />
  </Center>
);

export default Spinner;
