import * as R from 'ramda';

import { useMsal } from '@azure/msal-react';

export const ROLES = {
  Hq: 'PS_HQ',
  MarketManager: 'PS_MARKET_MANAGER',
  HubManager: 'PS_HUB_MANAGER',
  MarketEventManager: 'PS_MARKET_EVENT_MANAGER',
  EventManager: 'PS_EVENT_MANAGER',
  Specialist: 'PS_SPECIALIST'
};

const ACTIVE_ACCOUNT_ROLES_PATH = ['idTokenClaims', 'roles'];

/**
 * useRolesAuth
 * A hook that binds together the MSAL auth provider and our API's roles.
 * Provides an easy way to check against the current logged in users roles.
 *
 */
const useAuth = () => {
  const { instance } = useMsal();

  /**
   * hasAnyOfRoles
   *
   * Takes an array roles and check if the user has any of the following roles.
   * If user have at least one of the sent in roles, it returns true otherwise false.
   *
   * @param {string[]} roles
   * @returns {bool}
   *
   */
  const hasAnyOfRoles = (roles = []) => {
    const activeAccount = instance?.getActiveAccount();

    const userRoles = R.pathOr([], ACTIVE_ACCOUNT_ROLES_PATH, activeAccount);

    for (const r of roles) {
      if (R.includes(r, userRoles)) {
        return true;
      }
    }
    return false;
  };

  /**
   * hasRole
   *
   * Takes a role in form of it's name and returns a bool wether the user have that specific role.
   *
   * @param {string} roleName
   * @returns {bool}
   *
   */
  const hasRole = (roleName) => {
    const activeAccount = instance?.getActiveAccount();

    return R.includes(roleName, R.pathOr([], ACTIVE_ACCOUNT_ROLES_PATH, activeAccount));
  };

  /**
   * authResources
   *
   * Returns an object with keys to specific authorzation resources.
   * An authorization resource is a specific role that says that the user have access
   * to eg a market, a hub or an event. Depending on the user role, example PS_HQ the user
   * does not need authorization resource roles since it has access to everything.
   * Wheres the role PS_MARKET_MANAGER need to have a market resouce role but no event or hub resource roles.
   * A Hub manager in turn needs both a market resource as well as a specific hub resource role,
   * same goes for lower roles such as Specialist.
   *
   * A resource role is specified with the prefix "#" eg "#MARKET_se" is the market resource role
   * with the value of "se" (Swedens market) and a role "#HUB_se_hub_ded2f02e-c9b7-4bcc-9992-0566572a779a"
   * means the user have access to a hub with the ID of "se_hub_ded2f02e-c9b7-4bcc-9992-0566572a779a".
   *
   * @returns {hubId: [], eventId: [], marketId: []}
   *
   */
  const authResources = () => {
    const activeAccount = instance?.getActiveAccount();

    const userRoles = R.pathOr([], ACTIVE_ACCOUNT_ROLES_PATH, activeAccount);

    const resourcePrefixMatcher = R.match(/^#(.+?(?=_))_(.+)/);

    return R.reduce(
      (acc, role) => {
        const parts = resourcePrefixMatcher(role);

        if (R.isEmpty(parts)) {
          return acc;
        }

        const idField = parts?.[1]?.toLowerCase() + 'Id';
        const resourceId = parts?.[2];

        if (!acc[idField]) {
          return acc;
        }

        const prevResources = acc[idField];

        return {
          ...acc,
          [idField]: R.append(resourceId, prevResources)
        };
      },
      { hubId: [], marketId: [], eventId: [] },
      userRoles
    );
  };

  /**
   * logout
   *
   * Logs out the current signed in user.
   *
   * @returns {void}
   *
   */
  const logout = () => {
    instance?.logout();
  };

  /**
   * userName
   *
   * Returns the signed in users username.
   *
   * @returns {string | undefind}
   *
   */
  const userName = () => {
    const activeAccount = instance?.getActiveAccount();
    return activeAccount?.username;
  };

  /**
   * userFullName
   *
   * Returns the users first and last name (givenName, familyName).
   *
   * @returns {string}
   *
   */
  const userFullName = () => {
    const activeAccount = instance?.getActiveAccount();

    const firstName = activeAccount?.idTokenClaims?.given_name ?? '';
    const lastName = activeAccount?.idTokenClaims?.family_name ?? '';

    return R.trim(`${firstName} ${lastName}`);
  };

  return {
    hasRole,
    authResources,
    logout,
    userName,
    userFullName,
    hasAnyOfRoles
  };
};

export default useAuth;
