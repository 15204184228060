import React from 'react';

import { useAuth, useDato } from '~app/hooks';
import { ROLES } from '~app/hooks/useAuth';

import GQL_DATO_SIDEBAR_MENU from '~app/apollo/dato/queries/sidebar_menu';

import {
  NavigationDrawerFutureGroupTypes,
  useNavigationDrawerFuture
} from '@polestar/component-warehouse-react';
import { InternalNavigationDrawerFuture } from '@polestar/component-warehouse-react/internal';
import * as R from 'ramda';
import { useNavigate } from 'react-router-dom';
import { generateUrl } from '~routes/helpers';

const Menu = () => {
  const navigate = useNavigate();
  const { closeNavigationDrawer } = useNavigationDrawerFuture();
  const { hasRole } = useAuth();

  const [dato, datoLoading] = useDato(GQL_DATO_SIDEBAR_MENU);

  // Main links
  const markets = { view: 'markets', text: dato?.menu?.menuItemMarkets };
  const hubs = { view: 'hubs', text: dato?.menu?.menuItemHubs };
  const cars = { view: 'cars', text: dato?.menu?.menuItemCars };
  const events = { view: 'events', text: dato?.menu?.menuItemEvents };
  const vdms = { view: 'vdms', text: dato?.menu?.menuItemCarManagement };

  // Secondary links
  const releaseNotes = { view: 'releaseNotes', text: dato?.menu?.menuItemReleaseNotes };
  const support = { view: 'support', text: dato?.menu?.menuItemSupport };

  if (datoLoading) {
    return null;
  }

  let mainOptions = [];

  if (hasRole(ROLES.Hq)) {
    mainOptions = [markets, hubs, cars, events, vdms];
  } else {
    if (hasRole(ROLES.MarketManager)) {
      mainOptions = [...mainOptions, markets, hubs, cars, events, vdms];
    }
    if (hasRole(ROLES.HubManager)) {
      mainOptions = [...mainOptions, hubs];
    }
    if (hasRole(ROLES.MarketEventManager) || hasRole(ROLES.EventManager)) {
      mainOptions = [...mainOptions, events];
    }
    if (hasRole(ROLES.Specialist)) {
      mainOptions = [...mainOptions, hubs, events];
    }
  }

  mainOptions = R.uniqBy(R.prop('view'), mainOptions);

  const secondaryOptions = [releaseNotes, support];

  const optionToLink = (option) => ({
    label: option?.text,
    href: generateUrl(option?.view),
    onClick: (e) => {
      e.preventDefault();
      navigate(generateUrl(option?.view));
      closeNavigationDrawer();
    },
    ['data-testid']: `menuItem-${option?.view}`
  });

  // drawer cant handle empty array of links without crashing
  return (
    mainOptions.length && (
      <InternalNavigationDrawerFuture
        groups={[
          {
            groupType: NavigationDrawerFutureGroupTypes.large,
            items: [...R.map(optionToLink, mainOptions)]
          },
          {
            groupType: NavigationDrawerFutureGroupTypes.small,
            items: [...R.map(optionToLink, secondaryOptions)]
          }
        ]}
      />
    )
  );
};

export default Menu;
