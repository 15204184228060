import React, { useState, useEffect } from 'react';

/**
 * applyBodyStyleOnModalState
 * We need to adjust the body styling because otherwise the page under the modal
 * will scroll and if the modals height is tall we will have two scrollbars.
 *
 * @params {bool} isOpen - Indicates if the modal is visible/open.
 * @returns {void}
 *
 */
const applyBodyStyleOnModalState = (isOpen) => {
  document.body.style.overflowY = isOpen ? 'hidden' : null;

  // Scrollbar is around 15px, if we skip this then the page under the modal
  // will move horizontally.
  document.body.style.paddingRight = isOpen ? '15px' : '';
};

import * as R from 'ramda';

const useModal = (modals) => {
  const [modal, setModal] = useState(null);

  useEffect(() => {
    return () => {
      applyBodyStyleOnModalState(false);
      setModal(null);
    };
  }, []);

  const handleCloseModal = () => {
    applyBodyStyleOnModalState(false);
    setModal(null);
  };

  const handleShowModal =
    (modalKey, props = {}) =>
    (extraProps = {}) => {
      const M = R.prop(modalKey, modals);

      if (!M) {
        return;
      }

      applyBodyStyleOnModalState(true);
      setModal(<M onClose={handleCloseModal} {...R.mergeAll(props, extraProps)} />);
    };

  return [handleShowModal, handleCloseModal, modal];
};

export default useModal;
