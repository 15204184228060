import { useContext } from 'react';

import { useQuery } from '@apollo/client';

import { LocaleContext } from '~app/providers/locale';

const useDato = (
  query,
  includeBrowserLocale = true,
  fetchPolicy = null,
  variables = {},
  overrideLocale = null
) => {
  const locale = overrideLocale || (includeBrowserLocale ? useContext(LocaleContext) : null);

  const { loading, error, data } = useQuery(query, {
    context: {
      isDatoReq: true
    },
    variables: {
      locale,
      ...variables
    },
    fetchPolicy: fetchPolicy ? { fetchPolicy } : {}
  });

  return [data, loading, error];
};

export default useDato;
