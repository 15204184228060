import { useEffect, useRef, useState, useCallback } from 'react';

import isDeepEqual from 'fast-deep-equal/react';

import * as R from 'ramda';

/**
 * useTemplateSchedule
 *
 * This hooks helps with managing data for a schedule.
 * Can be used to delete, mutate, detect changes and keep track of the state.
 *
 *
 *
 */
const useTemplateSchedule = originalSlots => {
  const [hasChanged, setHasChanged] = useState(false);
  const [slots, setSlots] = useState(originalSlots);

  const orgSlotsRef = useRef(originalSlots);

  orgSlotsRef.current = originalSlots;

  useEffect(() => {
    setSlots(orgSlotsRef.current);
    setHasChanged(false);
  }, [orgSlotsRef.current]);

  const slotsRef = useRef(slots);

  if (!isDeepEqual(slotsRef.current, slots)) {
    slotsRef.current = slots;
  }

  useEffect(() => {
    const isDiff = !isDeepEqual(slotsRef.current, orgSlotsRef.current);
    setHasChanged(isDiff);
  }, [slotsRef.current]);

  // Taskes a `slotId` and a `slot` and merges the changes from
  // the sent in with the original slot.
  // Since it's a merge, only the changed fields needs to be sent in.
  // So for example if all you want to change is the bookingType, only that field
  // needs to be present in the `slot` argument.
  const handleMutateById = useCallback((slotId, slot) => {
    const foundIndex = slotsRef.current.findIndex(x => x.id === slotId);

    if (foundIndex === -1) {
      return;
    }

    const foundSlot = R.clone(slotsRef.current[foundIndex]);

    const modifiedSlot = R.mergeDeepLeft(slot, foundSlot);

    setSlots([
      ...slotsRef.current.slice(0, foundIndex),
      modifiedSlot,
      ...slotsRef.current.slice(foundIndex + 1)
    ]);
  }, []);

  // Can mutate multiple slots by providing a callback function `queryFn`.
  // The callback need to return all slots with their changes, not only the slots
  // that did change.
  const handleMutateByQuery = useCallback(queryFn => {
    if (!R.is(Function, queryFn)) {
      return;
    }

    const changes = queryFn(R.clone(slotsRef.current));

    setSlots(changes);
  }, []);

  // Deletes a slot based on `slotId`
  const handleDeleteById = useCallback(slotId => {
    const foundIndex = slotsRef.current.findIndex(x => x.id === slotId);

    if (foundIndex === -1) {
      return;
    }

    setSlots([...slotsRef.current.slice(0, foundIndex), ...slotsRef.current.slice(foundIndex + 1)]);
  }, []);

  return {
    mutateById: handleMutateById,
    mutateByQuery: handleMutateByQuery,
    deleteById: handleDeleteById,
    state: slots,
    hasChanged: hasChanged
  };
};

export default useTemplateSchedule;
