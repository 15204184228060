import React from 'react';
import { any, object } from 'prop-types';

import ErrorPage from '~app/components/ErrorPage';

/**
 * There is no hook that replaces getDerivedStateFromError or componentDidCatch as of right now.
 * > "There are no Hook equivalents for these methods yet, but they will be added soon."
 * @see https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      eventId: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      error
    };
  }

  render() {
    const { error } = this.state;
    const { children, staticContext } = this.props;

    staticContext.statusCode = 500;

    if (!error) {
      return children;
    }

    return <ErrorPage>{error?.message ?? null}</ErrorPage>;
  }
}

ErrorBoundary.propTypes = {
  children: any /* eslint-disable-line react/forbid-prop-types */,
  staticContext: object
};

ErrorBoundary.defaultProps = {
  staticContext: {}
};

export default ErrorBoundary;
