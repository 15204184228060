import React from 'react';

import 'notyf/notyf.min.css';
import 'purecss/build/base-min.css';
import 'purecss/build/forms-min.css';
import '~app/Global.css';

import { useLocation } from 'react-router-dom';
import AppRouter from '~app/router';

import ErrorBoundary from '~app/components/ErrorBoundary';
import Menu from '~app/components/Menu';

import { MAX_WIDTH } from '~app/styles/variables';

import styled from '@emotion/styled';

import { PageContent } from '@polestar/component-warehouse-react';
import { InternalPolestarApp } from '@polestar/component-warehouse-react/internal';
import Header from '~app/components/Header';
import useDatadogRum from '~app/hooks/useDatadogRum';
import { useDatadogSPANavigationTitles } from '~app/hooks/useDatadogRum/useDatadogRum';

export const Container = styled.div`
  height: 100%;
  min-height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  min-height: 100%;
  max-width: ${MAX_WIDTH};
`;

const App = () => {
  const location = useLocation();
  useDatadogRum();
  useDatadogSPANavigationTitles();

  // Currently /customer-consent is the only view that does not use
  // TDP's layout (menu and header).
  // If this change in the future, break this part out to make it more maintable.
  if (location?.pathname?.startsWith('/customer-consent')) {
    return <AppRouter />;
  }

  return (
    <InternalPolestarApp style={{ width: '100%' }}>
      <Header />
      <Menu />
      <PageContent>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </PageContent>
    </InternalPolestarApp>
  );
};

export default App;
