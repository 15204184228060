import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import getRuntimeConfig from '~root/src/config';
import { useLocation } from 'react-router-dom';

const config = getRuntimeConfig();
const isRUMEnabled = config.stage === 'prod';

export function useDatadogSPANavigationTitles() {
  const location = useLocation();
  useEffect(() => {
    if (isRUMEnabled) {
      datadogRum.startView({ name: location.pathname });
    }
  }, [location.pathname]);
}

const useDatadogRum = () => {
  useEffect(() => {
    if (isRUMEnabled) {
      datadogRum.init({
        applicationId: config.datadog.applicationId,
        clientToken: config.datadog.clientToken,
        service: 'test-drive-front',
        version: 'no-version',
        env: config.stage,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        site: 'datadoghq.eu',
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        silentMultipleInit: true,
        trackViewsManually: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
    }
  }, [isRUMEnabled]);

  return datadogRum;
};

export default useDatadogRum;
