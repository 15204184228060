import * as R from 'ramda';
import React, { createContext, useEffect, useState } from 'react';
import { useAppConfig } from '~app/lib/featureFlags';

export const FeatureFlagContext = createContext({});

const FeatureFlagProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [featureFlags, setFeatureFlags] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const { appConfigClient, initialConfigurationToken } = useAppConfig();
  const updateIntervalMilliSeconds = 300000; // 5 min

  const getFeatureFlags = async (token) => {
    await appConfigClient
      .getLatestConfiguration({ ConfigurationToken: token })
      .then(({ Configuration, NextPollConfigurationToken }) => {
        setLoading(false);
        setSessionToken(`${NextPollConfigurationToken}`);

        // This may be empty if the client already has the latest version of configuration
        if (R.isEmpty(Configuration)) return;

        const response = new TextDecoder().decode(Configuration);
        const flags = JSON.parse(response);
        setFeatureFlags(flags);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (initialConfigurationToken) getFeatureFlags(initialConfigurationToken);
  }, [initialConfigurationToken, appConfigClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      getFeatureFlags(sessionToken);
    }, updateIntervalMilliSeconds);

    return () => clearInterval(interval);
  }, [updateIntervalMilliSeconds, sessionToken, appConfigClient]);

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        loading
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
