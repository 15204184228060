import React, { createContext } from 'react';

import moment from 'moment-timezone';

import append from 'ramda/src/append';

const FALLBACK_LOCALE = 'en-gb';

export const I18nContext = createContext([]);

const I18nProvider = ({ children }) => {
  const userLanguages = navigator.languages;
  const locales = append(FALLBACK_LOCALE, userLanguages);

  // Set moment.js current locale globablly.
  moment.locale(navigator.language);

  return <I18nContext.Provider value={locales}>{children}</I18nContext.Provider>;
};

export default I18nProvider;
