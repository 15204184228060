import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import routes from '~routes';

// Looks like @loadable/component full-dynamic import does not work with babel resolver aliases such as '~app' at the moment
const LoadableView = loadable((props) => import(`../views/${props.view}/index`));

const AppRouter = () => (
  <Routes>
    {routes.map((route) => (
      <Route
        key={`view.${route.name}`}
        exact={`${route.exact}`}
        path={route.path}
        element={<LoadableView view={route.view} />}
      />
    ))}
  </Routes>
);

export default AppRouter;
