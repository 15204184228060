import { useEffect, useState } from 'react';

import { isNil } from 'ramda';

const useTimer = (time, callback, manualStart = false) => {
  const [timeoutId, setTimeoutId] = useState();

  const start = () => {
    clearTimeout(timeoutId);
    const id = setTimeout(callback, time);
    setTimeoutId(id);
  };

  useEffect(() => {
    if (isNil(time) || isNil(callback)) {
      return;
    }

    if (!manualStart) {
      start();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const stop = () => {
    clearTimeout(timeoutId);
  };

  if (isNil(time) || isNil(callback)) {
    return null;
  }

  return [start, stop];
};

export default useTimer;
