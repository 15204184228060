import styled from '@emotion/styled';

import { Colors, H1, H2, Spacings } from '@polestar/component-warehouse-react';

export const Container = styled.div`
  margin-bottom: ${Spacings.xLarge};
`;

export const Title = styled(H1)`
  color: ${Colors.stormGrey};
`;

export const SubTitle = styled(H2)``;
