import React, { createContext, useState } from 'react';

import { useResponse } from '~app/hooks';

import Spinner from '~app/components/Spinner';

import { useQuery } from '@apollo/client';
import GQL_TIMEZONES from '~app/apollo/queries/timezones';

export const TimezonesContext = createContext([]);

const TimezonesProvider = ({ children }) => {
  const [timezones, setTimezones] = useState([]);

  const { loading, error, data } = useQuery(GQL_TIMEZONES);

  useResponse({
    error,
    data,
    onError: {
      prefixMessage: 'Could not fetch timezones'
    },
    onSuccess: {
      callback: data => {
        setTimezones(data ?? []);
      }
    }
  });

  if (loading) {
    return <Spinner />;
  }

  return <TimezonesContext.Provider value={timezones}>{children}</TimezonesContext.Provider>;
};

export default TimezonesProvider;
