import React from 'react';

import styled from '@emotion/styled';

import { useAuth, useDato } from '~app/hooks';

import { device } from '~app/lib/media-query';

import PageTitle from '~app/components/PageTitle';

import GQL_DATO_ERROR_VIEW from '~app/apollo/dato/queries/error_view';

import * as R from 'ramda';

const Container = styled.div`
  padding: 0;
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;

  padding: 0 60px 60px 60px;
  max-width: 1440px;
  position: relative;

  @media ${device.mobile} {
    padding: 24px 24px 0 24px;
  }
`;

// Fallback texts for ErrorPage since it can be that we need to show it when it fails to fetch Dato.
const DEFAULT_TEXTS = {
  errorView: {
    title: 'Sorry',
    subTitle: 'Something went wrong',
    textsTip: 'Try and reload the page',
    textsContact: 'If the problem persists please contact support at',
    textsContactEmail: 'testdrivesupport@polestar.com',
    textsSignOut: 'Sign out',
    textsTryDifferentUser:
      'to try with a different user or re-sign in to apply newly added security roles.'
  }
};

const ErrorPage = ({ children }) => {
  const { logout } = useAuth();

  const [dato, datoLoading] = useDato(GQL_DATO_ERROR_VIEW);

  if (datoLoading) {
    return null;
  }

  const datoWithFallback = R.mergeDeepRight(DEFAULT_TEXTS, dato);

  const handleSignOut = () => {
    logout();
  };

  return (
    <Container data-testid="error-page">
      <PageTitle
        title={datoWithFallback?.errorView?.title}
        subTitle={datoWithFallback?.errorView?.subTitle}
      />

      {children}

      <p>{datoWithFallback?.errorView?.textsTip}</p>

      <p>
        {datoWithFallback?.errorView?.textsContact}{' '}
        <a href={`mailto:${datoWithFallback?.errorView?.textsContactEmail}`}>
          {datoWithFallback?.errorView?.textsContactEmail}
        </a>
      </p>

      <p>
        <a href="#" onClick={handleSignOut} className="link-with-underline-inherit">
          {datoWithFallback?.errorView?.textsSignOut}
        </a>{' '}
        {datoWithFallback?.errorView?.textsTryDifferentUser}
      </p>
    </Container>
  );
};

export default ErrorPage;
