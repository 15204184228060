import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import getRuntimeConfig from '~config';
import 'regenerator-runtime/runtime';

import { AppWrapper } from './AppWrapper';

const config = getRuntimeConfig();

const renderApplication = (createRootMethod) => {
  const root = createRootMethod(document.getElementById('root'));

  root.render(<AppWrapper />);
};
if (config.build.mode === 'development' || !config.build.isomorphic) {
  renderApplication(createRoot);

  if (typeof module.hot !== 'undefined') {
    module.hot.accept(); /* eslint-disable-line no-undef */
  }
} else {
  loadableReady(() => renderApplication(hydrateRoot));
}
